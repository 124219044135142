<template>
    <v-container>
        <grid-click :liste="liste" :addPhoto="addPhoto"/>
    </v-container>
</template>

<script>
import GridClick from '../components/GridClick.vue'
import {collection, getDocs, doc, updateDoc} from 'firebase/firestore';
import {uploadBytes,getDownloadURL,ref, deleteObject} from 'firebase/storage'
import {compression, firestore, storage} from '../main'

export default {
  components: { GridClick },

    data:()=>{
        return {
            liste:[]
        }
    },
    methods:{
      async addPhoto(e,continent) {
            if (! (e.target.files.length == 1))
                return
            if (continent.image_url && continent.image_url.length > 0)
                await deleteObject(ref(storage,continent.image_url));
            if (continent.thumbnail_url && continent.thumbnail_url.length > 0)
                await deleteObject(ref(storage,continent.thumbnail_url));
            
            let ref_thumbnail = ref(storage, `continents/${continent.firestore_id}/thumbnail-image_profil.${e.target.files[0].name.split('.').pop()}`);
            let data_thumbnail = await compression(e.target.files[0],600,0.9)
            await uploadBytes(ref_thumbnail, data_thumbnail);
            let link_thumbnail = await getDownloadURL(ref_thumbnail);
            let continent_ref = doc(firestore, "continents", continent.firestore_id);
            await updateDoc(continent_ref, {thumbnail_url:link_thumbnail});
            continent.thumbnail_url = link_thumbnail;
            this.$forceUpdate();

        },
    },
    async created(){
        let docs = await getDocs(collection(firestore,"continents"));
        this.liste = docs.docs.map(doc=>{return {...doc.data(),link:"continent/"+doc.id, firestore_id:doc.id}});
    }
}
</script>

<style>

</style>